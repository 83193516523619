import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApiService, SiteIdService} from '@app/services';
import {CurrencyPipe, DatePipe} from '@angular/common';
import {RouterLink} from '@angular/router';
import {map, Subject, takeUntil} from 'rxjs';
import {ImgixPipe, ToSlugPipe} from '@app/pipes';
import {
  CalendarIcon,
  CroissantIcon,
  DotIcon,
  MountainIcon,
  PalmTreeIcon,
  PromotionCategoryIcon,
  StarIcon,
  SucculentIcon,
  WhaleIcon,
} from '@app/icons';
import {Promotion, Vendor} from '@app/models';
import {defaultRequestState} from '@app/services/search-driver';
import {PriceService2} from '@app/services/price2.service';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
  standalone: true,
  selector: 'app-promotion-card',
  imports: [
    ToSlugPipe,
    ImgixPipe,
    RouterLink,
    CurrencyPipe,
    MountainIcon,
    WhaleIcon,
    PalmTreeIcon,
    SucculentIcon,
    CroissantIcon,
    CalendarIcon,
    StarIcon,
    DotIcon,
    DatePipe,
    PromotionCategoryIcon,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './promotion-card.component.html',
  styleUrls: ['./promotion-card.component.css'],
})
export class PromotionCardComponent implements OnInit, OnDestroy {
  siteID;
  phone: string;
  tel: string;
  minPrice = 0;
  sailingsString = '';
  @Input() promotion: Promotion | undefined = undefined;
  promotionVendor: Vendor | null = null;
  routeQueryParams = {
    limit: defaultRequestState.limit,
    page: defaultRequestState.page,
    'sort-field': defaultRequestState.sort.field,
    'sort-direction': defaultRequestState.sort.direction,
  };

  private destroy$ = new Subject<void>();

  constructor(
    private siteIdService: SiteIdService,
    private priceService: PriceService2,
    private api: ApiService
  ) {
    this.siteID = this.siteIdService.site.siteID;
    this.phone = this.siteIdService.site.phone;
    this.tel = this.phone.replace(/\D/g, '');
  }

  ngOnInit() {
    if (this.promotion && this.promotion?.Sailings.length > 0) {
      // fetch common sailings vendor
      this.promotionVendor = this.getPromoVendor(this.promotion);

      // fetch OD pricing and calculate best pricing
      const sailingsString = this.promotion?.Sailings.map(
        sailing => sailing.ID
      ).join(',');
      this.api
        .get<{ID: number; Value: number}[]>(
          `/promotions/minprices?ids=${sailingsString}`
        )
        .pipe(
          map(prices => {
            if (prices.length > 0) {
              const cruisePricing = prices.map(cruisePricing => {
                return {
                  sigID: cruisePricing.ID,
                  minPrice: cruisePricing.Value,
                };
              });
              return this.priceService.getPromoCruiseGroupDisplayPrice(
                this.promotion!,
                cruisePricing
              );
            }
            return null;
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(promoMinPricing => {
          if (
            promoMinPricing &&
            promoMinPricing.displayPrice > 0 &&
            !promoMinPricing.source?.privatePromotion &&
            promoMinPricing.source?.override?.DiscountType !==
              'Call for Pricing'
          ) {
            this.promotion!.minPrice = promoMinPricing.displayPrice;
          }
        });
    }
  }

  compareDates(date: Date | string): boolean {
    const today = new Date();
    const expire = new Date(date);

    const diffInMilliseconds = expire.getTime() - today.getTime();
    const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

    return diffInDays <= 14;
  }

  // if no sailings or different sailing vendors, return null
  private getPromoVendor(promotion: Promotion): Vendor | null {
    if (promotion.Sailings.length === 0) return null;

    const uniqueVendors = promotion.Sailings.map(
      sailing => sailing.Vendor
    ).filter(
      (vendorItem, idx, vendors) =>
        vendors.findIndex(vendor => vendor.ID === vendorItem.ID) === idx
    );

    if (uniqueVendors.length > 1) return null;

    return uniqueVendors[0];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
